import React, { useState, useEffect } from 'react';
import loadable from '@loadable/component';

import { SkeletonHomeAdvertisingView } from 'views/home/skeleton';

import useGetSubscription from 'hooks/use-get-subscription';
import useGetPricing from 'hooks/use-get-pricing';
import { useNavigationContext } from 'contexts/navigation-context';
import { isAuthenticated } from 'services/authentication-service';

import { ACCOUNT_ROUTE } from 'constants/navigation';

const LazyStartSubscriptionPage = loadable(() => import('suspended-page/subscription/start-up'));

const StartSubscriptionPage = () => {
  const { hasSub, getSubLoading } = useGetSubscription();
  const { pricingModels, getPricingLoading } = useGetPricing();
  const [loading, setLoading] = useState(true);

  const authenticated = isAuthenticated();

  const {
    navigate = () => {},
    homePageOnly = () => {},
  } = useNavigationContext();

  useEffect(() => {
    if (!getSubLoading && !getPricingLoading) {
      setLoading(false);
      if (hasSub) {
        setTimeout(() => navigate(ACCOUNT_ROUTE), 100);
      }
    }
    // eslint-disable-next-line
  }, [getSubLoading, getPricingLoading]);

  if (!authenticated) {
    homePageOnly();
    return (<SkeletonHomeAdvertisingView />);
  }

  return (
    <LazyStartSubscriptionPage loading={loading} subscriptionPrices={pricingModels}/>
  );
};

export default StartSubscriptionPage;
